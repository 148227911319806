export { } // to make it a module

declare global { // to access the global type String
    interface String {
        /**
         * Determine if string is blank/empty.
         * @deprecated Use exported function
         */
        isEmpty(): boolean;
        /**
         * Escape characters in string for HTML content.
         * @deprecated Use exported function
         */
        escapeHtml(): string;
    }
}

String.prototype.isEmpty = function () {
    console.warn('String.isEmpty is deprecated!');
    return (this.length === 0 || !this.trim());
};

String.prototype.escapeHtml = function () {
    console.warn('String.escapeHtml is deprecated!');
    return this
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};

export function isEmpty(value?: string): boolean {
    return (value == undefined || value == null || value?.length === 0 || value?.trim() == '');
};

export function escapeHtml(value?: string): string {
    return value
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};
